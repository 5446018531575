<section class="body-navbar">
  <div class="container__">
    <div class="tag">
      <ul class="ul-tag">
        <li><a class="breadcrumb-link" (click)="go('/')">Home</a></li>
        <li> / </li>
        <li>
          <a class="breadcrumb-link" (click)="go('/events')">
            Event
          </a>
        </li>
        <li> / </li>
        <li>
          <span class="tag-tours">{{ event?.title }}</span>
        </li>
      </ul>
    </div>
    <div class="title-tour">
      <span class="font-title-tour">
        <b>{{ event?.title }}</b>
      </span>
      <span class="font-code-title" *ngIf="false && event?.code"> [{{ event?.code }}]</span>
    </div>
  </div>

  <!-- --------------- Image ------------------- -->
  <div class="container____">
    <!-- <div class="main-img-tour">
      <img class="img-tour" [src]="event?.image_header" />
    </div> -->

    <div
      id="carouselEvent"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            class="d-block w-100"
            [src]="event?.image_header"
          />
        </div>
        <ng-container *ngIf="event?.images">
          <div *ngFor="let item of event?.images" class="carousel-item">
            <img class="d-block w-100" [src]="item?.url" />
          </div>
        </ng-container>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselEvent"
        role="button"
        data-slide="prev"
      >
        <span
          class="carousel-control-prev-icon"
          aria-hidden="true"
        ></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselEvent"
        role="button"
        data-slide="next"
      >
        <span
          class="carousel-control-next-icon"
          aria-hidden="true"
        ></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>

  <!-- --------------- Concept ------------------- -->
  <div class="container___" *ngIf="event?.concept">
    <div class="concept">
      <span style="color: #d45079">"{{ event?.concept }}"</span>
    </div>
  </div>

  <!-- --------------- Block Details ------------------- -->
  <div class="container___ mt-4">
    <div class="row flex-column-reverse flex-md-row">
      <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <!-- Service -->
        <div class="service-tag" style="text-align: center">
          <div class="row">
            <div class="col-1 iocn-hidden">
              <span>test</span>
            </div>
            <div class="col-2 icon-service">
              <img src="./assets/images/tours/icon/world.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">English</span>
            </div>
            <div class="col-2 icon-service">
              <img src="./assets/images/tours/icon/baby.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">No charge Infant</span>
            </div>
            <div class="col-2 icon-service">
              <img src="./assets/images/tours/icon/guides.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">English Greeter</span>
            </div>
            <div class="col-2 icon-service">
              <img src="./assets/images/tours/icon/bottle.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">Free Drinking Water</span>
            </div>
            <div class="col-2 icon-service">
              <img src="./assets/images/tours/icon/van.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">Pick up at pick-up points</span>
            </div>
          </div>
        </div>

        <div class="service-tag-m" style="text-align: center">
          <hr />
          <div class="row">
            <div class="col-4 icon-service">
              <img src="./assets/images/tours/icon/world.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">English</span>
            </div>
            <div class="col-4 icon-service">
              <img src="./assets/images/tours/icon/baby.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">No charge Infant</span>
            </div>
            <div class="col-4 icon-service">
              <img src="./assets/images/tours/icon/guides.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">Professional Guide</span>
            </div>
          </div>
          <div class="row">
            <div class="col-4 icon-service">
              <img src="./assets/images/tours/icon/bottle.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">Free Drinking Water</span>
            </div>
            <div class="col-4 icon-service">
              <img src="./assets/images/tours/icon/van.png" /><br /><br
                class="br-hidd"
              />
              <span class="icon-text-s">Pick up at pick-up points</span>
            </div>
          </div>
          <hr />
        </div>

        <!-- Preriod -->
        <div class="content period pad-t-b">
          <div class="description-title">
            <span class="content-title">Event Date : {{ displayDate(event?.start_date, 'DD') }} - {{ displayDate(event?.end_date, 'DD MMMM YYYY') }}</span>
          </div>
          <br />
          <!-- <div class="description-detail font-1r">
            <span [innerHTML]="event?.description"></span>
          </div> -->
        </div>

        <!-- Video -->
        <div class="content video pad-t-b" *ngIf="event?.video">
          <div class="video-content" *ngIf="event?.video?.is_iframe">
            <div [innerHTML]="event?.video?.link"></div>
          </div>
          <video playsinline *ngIf="!event?.video?.is_iframe">
            <source [src]="event?.video?.link" type="video/mp4">
          </video>
          <br />
        </div>

        <!-- --------------- Tour Description -------------------- -->
        <div class="content no-border" *ngIf="event?.image">
          <div class="font-1r">
            <img [src]="event?.image" class="d-block w-100">
          </div>
        </div>

        <!-- --------------- Tour Description -------------------- -->
        <div class="content description pad-t-b">
          <div class="description-title">
            <span class="content-title">Description</span>
          </div>
          <br />
          <div class="description-detail font-1r">
            <span [innerHTML]="event?.description"></span>
          </div>
        </div>

        <!-- --------------- Images -------------------- -->
        <div class="content tour-images" *ngIf="false">
          <div
            id="carouselEvent"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  class="d-block w-100"
                  [src]="event?.images[0]?.url"
                />
              </div>
              <ng-container *ngIf="event?.images">
                <div *ngFor="let item of event?.images" class="carousel-item">
                  <img class="d-block w-100" [src]="item?.url" />
                </div>
              </ng-container>
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselEvent"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselEvent"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>

        <!-- --------------- Schedule -------------------- -->
        <div class="content itinerary pad-t-b">
          <div class="itinerary-title">
            <span class="content-title">Schedule</span>
          </div>
          <br />
          <div class="itinerary-detail font-1r">
            <div class="tour-itinerary-detail font-1r">
              <ng-container *ngIf="event?.schedules">
                <table>
                  <tr *ngFor="let item of event?.schedules">
                    <td class="w-30 td-1">{{ item?.time }}</td>
                    <td class="td-pad"><span [innerHTML]="item?.detail"></span></td>
                  </tr>
                </table>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- --------------- Include -------------------- -->
        <div class="content include pad-t-b">
          <div class="include-title">
            <span class="content-title">Includes</span>
          </div>
          <br />
          <div class="content-includes font-1r">
            <ng-container *ngIf="event?.includes && event?.includes?.length > 0">
              <div *ngFor="let includes of event?.includes">
                <i class="fa fa-check" aria-hidden="true"></i>
                &nbsp;
                <span [innerHTML]="includes?.detail"></span>
                <br />
              </div>
            </ng-container>
          </div>
        </div>

        <!-- --------------- Tour Excludes -------------------- -->
        <div class="content excludes pad-t-b">
          <div class="excludes-title">
            <span class="content-title">Excludes</span>
          </div>
          <br />
          <div class="content-Excludes font-1r">
            <ng-container *ngIf="event?.excludes && event?.excludes?.length > 0">
              <div *ngFor="let exclude of event?.excludes">
                <i class="fa fa-times" aria-hidden="true"></i>
                &nbsp;
                <span [innerHTML]="exclude?.detail"></span>
                <br />
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Lantern 2023 Only -->
        <ng-container *ngIf="event?.details">
          <!-- Seat -->
          <div class="content pad-t-b" *ngIf="event?.details?.seat?.is_active">
            <div class="excludes-title">
              <span class="content-title">{{ event?.details?.seat?.title }}</span>
            </div>
            <br />
            <div class="content-seat font-1r">
              <ng-container *ngIf="event?.details?.seat?.images && event?.details?.seat?.images?.length > 0">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngFor="let item of event?.details?.seat?.images">
                    <img class="detail-image" [src]="item?.url" [alt]="item?.alt">
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!event?.details?.seat?.images || event?.details?.seat?.images?.length === 0">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <img class="detail-image" [src]="event?.details?.seat?.image" [alt]="event?.details?.seat?.title">
                  </div>
                </div>
              </ng-container>

              <br />
              <p *ngIf="event?.details?.seat?.detail" [innerHTML]="event?.details?.seat?.detail"></p>
            </div>
          </div>

          <!-- Map -->
          <div class="content pad-t-b" *ngIf="event?.details?.map?.is_active">
            <div class="excludes-title">
              <span class="content-title">{{ event?.details?.map?.title }}</span>
            </div>
            <br />
            <div class="content-map font-1r">
              <ng-container *ngIf="event?.details?.map?.images && event?.details?.map?.images?.length > 0">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngFor="let item of event?.details?.map?.images">
                    <img class="detail-image" [src]="item?.url" [alt]="item?.alt">
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!event?.details?.map?.images || event?.details?.map?.images?.length === 0">
                <img class="detail-image" [src]="event?.details?.map?.image" [alt]="event?.details?.map?.title">
              </ng-container>

              <p *ngIf="event?.details?.map?.detail" [innerHTML]="event?.details?.map?.detail"></p>
            </div>
          </div>

          <!-- Ticket -->
          <div class="content pad-t-b" *ngIf="event?.details?.ticket?.is_active">
            <div class="excludes-title">
              <span class="content-title">{{ event?.details?.ticket?.title }}</span>
            </div>
            <br />
            <div class="content-ticket font-1r">
              <ng-container *ngIf="event?.details?.ticket?.images && event?.details?.ticket?.images?.length > 0">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngFor="let item of event?.details?.ticket?.images">
                    <img class="detail-image ticket" [src]="item?.url" [alt]="item?.alt">
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!event?.details?.ticket?.images || event?.details?.ticket?.images?.length === 0">
                <img class="detail-image ticket" [src]="event?.details?.ticket?.image" [alt]="event?.details?.ticket?.title">
              </ng-container>

              <p *ngIf="event?.details?.ticket?.detail" [innerHTML]="event?.details?.ticket?.detail"></p>
            </div>
          </div>
        </ng-container>

        <!-- --------------- Terms and Conditions  -------------------- -->
        <div class="content terms-condition pad-t-b">
          <div class="terms-condition-title">
            <span class="content-title">Terms and conditions</span>
            <br /><br />
            <span
              class="text-term pointer font-1r"
              (click)="openModal(template)"
              ><u>Read More</u></span
            >
          </div>
        </div>
      </div>

      <!-- Booking -->
      <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <div class="form-book">
          <div class="blog-book">
            <div *ngIf="event?.types">
              <!-- Join & private -->
              <ng-container *ngIf="event?.types[0]">
                <div class="blog-price">
                  <span class="card-text-review color-2 font-blog-form">From</span
                  ><br />
                  <div class="col-12 blog-price_ mt-2">
                    <span class="card-text-more_ color-2 font-blog-currency">THB</span>
                    &nbsp;&nbsp;
                    <span class="card-text-more_ color-4 font-blog-price">
                      {{ event?.types[0]?.adult_price | number }}
                    </span>
                    &nbsp;&nbsp;
                    <span class="per-person_ color-2">per person</span>
                  </div>
                </div>
              </ng-container>

              <!-- Btn Book -->
              <div class="btn-book mt-3">
                <button
                  mat-raised-button
                  class="book-btn"
                  (click)="onClickBooking()"
                >
                  BOOK NOW
                </button>
              </div>
              <br />
              <hr />
              <div class="list-guides-book font-1r">
                <!-- <span>
                  <i class="fa fa-user" aria-hidden="true"></i> Guarantee daily departure with 2 adults
                </span>
                <br /> -->
                <span>
                  <i class="fa fa-book" aria-hidden="true"></i> Reserve at least 1 day(s) in advance.
                </span>
                <br />
                <span>
                  <i class="fa fa-check" aria-hidden="true"></i> No booking fees or any hidden cost.
                </span>
                <br />
                <span>
                  <i class="fa fa-check" aria-hidden="true"></i> Instant booking directly.
                </span>
                <br />
                <span>
                  <i class="fa fa-check" aria-hidden="true"></i> Bestprice.
                </span>
              </div>

              <button
                class="btn-download"
                mat-raised-button
                color="default"
                (click)="event?.document && openPDF()"
              >
                <i class="fa fa-print pointer" aria-hidden="true"></i> 
                DOWNLOAD
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<!-- ---------- Term & Condition ------------------ -->
<ng-template #template>
  <app-term-and-condition-event
    (onClose)="toggleTermDialog($event)"
    [isClose]="true"
    [liabilities]="event?.liabilities"
  ></app-term-and-condition-event>
</ng-template>