export const env =  {
  // New website
  JSON_URL: "assets/json/",
  SERVER_URL: "https://api.touringcenter.com/",
  API_URL: "https://api.touringcenter.com/api/",
  WEBSITE_URL: "https://touringcenter.com/",
  PAYPAL_ACCOUNT: "info@touringcnx.com",
  PAYPAL_KEY: "AeujZG5FQrI_MpxAttNySdA5lZMoaMTKduDYiHtn5Piuq0J8QdiHJ2cUH5s5LsSGOu_SABS4KfBTU_ck",
  LINE_NOTIFY_URL: "https://notify-api.line.me/api/notify",
  LINE_NOTIFY_TOKEN: "SSPWbyxIOtRdvgGOJEPpY8is2HACU6S8bEN6j5HTEyC"
}

export const env_dev = {
  JSON_URL: "assets/json/",
  SERVER_URL: "https://api.tourinchiangmai.com/",
  API_URL: "https://api.tourinchiangmai.com/api/",
  WEBSITE_URL: "https://dev.tour-in-chiangmai.com/",
  PAYPAL_ACCOUNT: "info@touringcnx.com",
  PAYPAL_KEY: "AQQ1zAgpzz2cz08zvjCwFcMwTPy-v6LuZVYr7ogA26o0FslzuSBQFx5-obuOwhjOh8GWukpe7i6nOTd0",
  LINE_NOTIFY_URL: "https://notify-api.line.me/api/notify",
  LINE_NOTIFY_TOKEN: "OOENYHPedXeZDotWyD5cVX6YuIdsQCkfwB1zMq1BWmB"
}

export const env_local = {
  JSON_URL: "assets/json/",
  SERVER_URL: "http://localhost:8000/",
  API_URL: "http://localhost:8000/api/",
  WEBSITE_URL: "http://localhost:4000/",
  PAYPAL_ACCOUNT: "info@touringcnx.com",
  PAYPAL_KEY: "AQQ1zAgpzz2cz08zvjCwFcMwTPy-v6LuZVYr7ogA26o0FslzuSBQFx5-obuOwhjOh8GWukpe7i6nOTd0",
  LINE_NOTIFY_URL: "https://notify-api.line.me/api/notify",
  LINE_NOTIFY_TOKEN: "cCqDJwuTB5sXaLcgN2zCBMLGrLygV5A38nT4rUARyIs",
}