import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import * as AOS from 'aos';
import { ApiServiceService } from "./../../services/api-service.service";
import { EnvService } from "src/app/services/env.service";

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.scss']
})
export class ToursComponent implements OnInit {
  public configs: any = {};
  public tour_categories = <any>'';

  public pagine: number;
  public Tours = <any>'';
  public Tours_List = <any>'';
  public CustomTours : Array<any> = [];
  public categories_ref = <any>'';
  public imgCoverPath = <any>'';

  public browser_variable: boolean = false;

  public actives : boolean = false;
  public actives_default : boolean = false;
  public actives_r : boolean = false;
  public actives_e : boolean = false;
  public actives_s : boolean = false;

  public typesOfcategories: string[] = [
    'All Tours',
    'Full Day',
    'Half Day',
    'Evening Trip',
    'Recommended Trip',
    'Experience Trip',
    // 'Special Trip'
  ];

  constructor(
    private router: Router,
    private scrollToService: ScrollToService,
    private apiService: ApiServiceService,
    private envService: EnvService
  ) {
    let config = this.envService.getConfig();
    this.configs = config;
  }

  // <<< Initial >>>
  ngOnInit(): void {
    this.callGetAllTours();
    AOS.init({
      once: true
    });

    this.imgCoverPath = `${this.configs.SERVER_URL}`+"images/cover-size.jpg";
  }

  // <<< -----------------------------------------
  // ------------------- Call API ----------------
  // ----------------------------------------- >>>
  // get tours
  callGetAllTours(): void {
    const url = "Website/Tour/GetAllTours";
    let setPayload = {};

    this.apiService
      .callPost(url, setPayload)
      .toPromise()
      .then(
        (result) => [
          (this.Tours = result),
          (this.Tours_List = this.Tours.data),
        ],
        (err) => [console.log(err)]
      )
      .then(() => {
        this.categories_ref = sessionStorage.getItem('categories_tours');

        if(this.categories_ref == 'Recommended Trip' || this.categories_ref == 'Experience Trip' || this.categories_ref == 'Special Trip'){
          this.tour_categories = this.categories_ref;
          this.changeListCategories();
          sessionStorage.setItem('categories_tours','');

          if(this.categories_ref == 'Recommended Trip'){
            this.actives_default = false;
            this.actives_r = true;
            this.actives_e = false;
            this.actives_s = false;
          }
          if(this.categories_ref == 'Experience Trip'){
            this.actives_default = false;
            this.actives_r = false;
            this.actives_e = true;
            this.actives_s = false;
          }
          if(this.categories_ref == 'Special Trip'){
            this.actives_default = false;
            this.actives_r = false;
            this.actives_e = false;
            this.actives_s = true;
          }
        }else{
          this.Tours_List = this.Tours.data;
          this.actives_default = true;
          this.actives_r = false;
          this.actives_e = false;
        }
      });
  }

  // <<< -----------------------------------------
  // ------------------- On event ----------------
  // ----------------------------------------- >>>
  // change categories
  changeListCategories(){
      //check type
      if(this.tour_categories == 'All Tours'){
                this.Tours_List = this.Tours.data;
                this.pagine = 1;
      }else{
      for(let i = 0; i <= this.Tours.data.length-1; i++){
               let category_ = this.Tours.data[i].categories;
               let timetype_ = this.Tours.data[i].timetype;
                  if(this.tour_categories == category_ || this.tour_categories == timetype_){
                        this.CustomTours.push(this.Tours.data[i])
            }
        }
            this.Tours_List = this.CustomTours;
            this.pagine = 1;
            this.CustomTours = [];
      }
  }

  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: 'listcategories'
    };
    this.scrollToService.scrollTo(config);
  }

  // <<< -----------------------------------------
  // ------------------- Set link ----------------
  // ----------------------------------------- >>>
  routeTour(event){
    this.router.navigate(['tour/'+event]);
  }

}
